import React from 'react'
import { graphql } from 'gatsby'
import { ArticleLayout } from "../layouts/"

export const query = graphql`
    query HomepageQuery {
        model: sanityPage(_id: { regex: "/(drafts.|)home/" }) {
            ...PageTemplate
        }
    }
`

const Homepage = (props) => {

    return (
        <ArticleLayout {...props} />
    )

}

export default Homepage
